header {
  display: flex;
  align-items: center;

  border-bottom: 1px solid #EAEAEA;
  margin-bottom: 37px;
}

header h1 {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}

h1 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 39px;
  display: flex;
  justify-content: center;
  color: #121212
}

h2 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #000000;
  text-align: left;
}

p {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  margin-top: 18px;
  color: #000000;
}

a {
  text-decoration-line: underline;
  font-size: 15px;
  font-weight: 300;
}

.body_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 50%;
}

@media (max-width: 480px) {
  .container {
    width: 90%;
  }
}