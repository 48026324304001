.main_title {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
  text-align: left;
}

.main_h2 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #121212;
  text-align: left;
  margin: 0px 0px 11px 0px;
}

.main_p {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  text-align: left;
  margin: 0;
}

.input {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.icon {
  margin-right: 30px;
  margin-left: 20px;
}

.next_button {
  background-color: #121212;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 300px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: #FFFFFF;
  margin-top: 40px;
}

.input2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
  margin-bottom: 37px;
}

header h1 {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}

header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EAEAEA;
  margin-bottom: 37px;
}

.title{
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}