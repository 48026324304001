.error_h1 {
    text-align: center;
    font-weight: 900;
    font-size: 40px;
}

.error_p {
    text-align: center;
    font-size: 20px;
}
.error_i{
    text-align: center;
    font-size: 18px;
    color: darkgrey;
}