.text1 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23px;
  color: #121212;
  margin-top: 30px;
}

a {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-decoration-line: underline;
  color: #121212;
}

.oplati_h1 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #121212;
}

.oplati_h2 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #000000;
}

.oplati_h3 {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #121212;
  margin: 0;
  padding-bottom: 30px;
}

#alert {
  background: rgba(131, 224, 254, 0.3);
  border-radius: 10px;
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

#alert_2 {
  background: #D2F0CD;
  border-radius: 10px;
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #121212;
}

#alert_3 {
  background: #FFDADA;
  border-radius: 10px;
  width: 300px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #121212;
}

#alert_text{
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: #121212;
}
#alert_text p{
  text-align: center;
}
.qr {
  width: 250px;
  height: 250px;
  padding: 25px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(67, 67, 67, 0.4);
  border-radius: 10px;
}
.oplati_qr{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.table{
  display: grid;
  grid-template-columns: 40% 60%;
  width: 50%;
  margin-left: 25%;
}
.button_pay{
  visibility: hidden;
}
@media (max-width: 480px) {
  .oplati_qr{
    visibility: hidden;
    width: 0;
  }
  .table_text{
    text-align: center;
  }
  .button_pay{
    visibility: visible;
    background-color: #121212;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 80%;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
    margin-top: 40px;
    margin-left: 10%;
  }
  .table{
    display: flex;
    width: 90%;
    margin-left: 5%;
}
}

@media (max-width: 1279px) {
  .oplati_qr{
    visibility: hidden;
    width: 0;
  }
  .table_text{
    text-align: center;
  }
  .button_pay{
    visibility: visible;
    background-color: #121212;
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 80%;
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #FFFFFF;
    margin-top: 40px;
    margin-left: 10%;
  }
  .table{
    display: block;
    width: 80%;
    margin-left: 10%;
}
}